import { getCountryConfigFromLocale } from '@/lib/i18n/getCountryConfigFromLocale';
import { CityData } from '@/types/CityData';

export function getCitiesFilteredByLocale<T extends CityData[]>(
  currentLocale: string,
  cities: T
): T {
  const { countryCode } = getCountryConfigFromLocale(currentLocale);

  return cities.filter(el => el.countryCode === countryCode) as T;
}
