import cls from 'clsx';
import { useTranslation } from 'next-i18next';
import { AnchorButton } from '@/components/atoms/Button/Button';
import { TextAndImage } from '@/components/atoms/TextAndImage/TextAndImage';
import { H3, P } from '@/components/atoms/Typography/Typography';
import { CloudinaryImage } from '@/components/local/CloudinaryImage/CloudinaryImage';
import nameSpacesConfig from '@/config/config.nameSpaces.json';
import { textAndImageSize } from '@/containers/HomePage/textAndImageSizes';
import { useData } from '@/lib/pageData/pageDataContext';
import styles from './Sustainability.module.scss';

export const Sustainability = () => {
  const { t } = useTranslation(nameSpacesConfig.pages);
  const { page, navLinks } = useData<'home'>();

  return (
    <TextAndImage
      as="div"
      className={styles.wrapper}
      classRight={cls(styles.image)}
      classLeft={cls('typography', styles.text)}
      wrapOnTop="right"
      left={
        <>
          <H3 version="level4">{t('home.sustainabilityBanner.heading')}</H3>
          <P>{t('home.sustainabilityBanner.text')}</P>
          <AnchorButton
            fullWidth
            href={navLinks.sustainability.url}
            variant="contained"
            color="secondary"
          >
            {t('home.sustainabilityBanner.cta')}
          </AnchorButton>
        </>
      }
      right={
        <CloudinaryImage
          src={page.images.sustainabilityBanner}
          alt=""
          layout="fill"
          objectFit="cover"
          sizes={textAndImageSize}
        />
      }
    />
  );
};
