import cls from 'clsx';
import { TrustPilotWidget } from '@/components/atoms/TrustPilotWidget/TrustPilotWidget';
import { getLangFromLocale } from '@/lib/i18n/getLangFromLocale';
import { Countries } from '@/types/Countries';
import { TrustPilotDataEntry } from '@/types/TrustPilotData';
import styles from '../HeaderSearch.module.scss';

type TestingPilotWidgetProps = {
  locale: string;
  trustPilotTheme: 'dark' | 'light';
  trustPilot: TrustPilotDataEntry | null;
  currentCountry: Countries;
  isBigScreen: boolean;
};

export const TestingPilotWidget = ({
  locale,
  currentCountry,
  trustPilot,
  trustPilotTheme,
  isBigScreen,
}: TestingPilotWidgetProps) => {
  const currentLang = getLangFromLocale(locale);

  return !!trustPilot && trustPilot.isPublished ? (
    <>
      <div className={styles.trustPilotContainer}>
        <TrustPilotWidget
          className={cls(styles.trustPilot, styles[currentLang])}
          locale={locale}
          dataTemplateId={trustPilot.dataTemplateId}
          dataBusinessUnitId={trustPilot.dataBusinessUnitId}
          url={trustPilot.url}
          theme={trustPilotTheme}
          currentCountry={currentCountry}
        />
      </div>
      {!isBigScreen ? <div style={{ width: '100%', height: '44px' }} /> : null}
    </>
  ) : null;
};
