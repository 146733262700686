import { NextRouter } from 'next/router';
import urlJoin from 'url-join';
import { radioProductCategories } from '@/components/local/ProductFilters/constants';
import type { CitySelectorCity } from '@/components/organisms/CitySelector/CitySelector';
import { getGa4Linker } from '@/lib/gtm/getGa4Linker';
import { getCountryConfigFromLocale } from '@/lib/i18n/getCountryConfigFromLocale';
import { Logger } from '@/lib/newRelic/Logger';
import { fetchLocalisedPathForCity } from '@/utils/fetch/fetchLocalisedPathForCity';
import { getLocaleByLanguage } from '@/utils/getLocaleByLanguage';

export const navigateToCityPage =
  (router: NextRouter, eBikesOnly?: boolean) =>
  async (selectedCity: CitySelectorCity) => {
    const currentLocale = router.locale!;
    const { countryCode, localeConfig } =
      getCountryConfigFromLocale(currentLocale);

    const countryLocales = localeConfig.locales.map(el => el.key);

    let locale = getLocaleByLanguage({ countryLocales, langCode: 'en' });

    if (countryCode === selectedCity.countryCode) {
      locale = currentLocale;
      const urlObject = {
        pathname: urlJoin('/', '[city]'),
        query: {
          ...router.query,
          city: selectedCity.code,
          ...(!!eBikesOnly ? { type: radioProductCategories.eBike } : {}),
        },
      };

      return router.push(urlObject, '', {
        locale,
      });
    } else {
      const results = await fetchLocalisedPathForCity(
        selectedCity,
        router.locale!
      ).catch(e => {
        Logger.warn('fetchLocalisedPathForCity failed to fetch', {
          error: JSON.stringify(e),
        });
      });

      if (results) {
        locale = results.bestFitLocale;
      }

      const ga4Id = getGa4Linker({ returnLinkerParam: true });
      const queryParams = ga4Id ? `?${ga4Id}` : '';
      const path = urlJoin('/', locale, selectedCity.code, queryParams);

      return window.location.assign(path);
    }
  };
