import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import { CityData } from '@/types/CityData';
import { NavLinkType } from '@/types/NavLinks';

interface Props {
  className?: string;
  navLinks?: NavLinkType[];
  localCities?: CityData[];
}

export const SeoNavBar = ({ localCities = [], navLinks = [] }: Props) => {
  const { t } = useTranslation();

  const localCitiesWithName = localCities.map(city => ({
    ...city,
    name: t(`common:cities.${city.code}`, city.name),
  }));

  return (
    <header>
      <nav>
        <VisuallyHidden>
          {/*SEO LINKS*/}
          {navLinks?.reduce((array, link) => {
            if (link && 'url' in link) {
              array.push(
                <Link key={link.key} href={link.url} prefetch={false}>
                  {t(`navLinks.${link.key}`)}
                </Link>
              );
            }
            return array;
          }, [] as React.ReactElement[])}
          {localCitiesWithName.map(city => (
            <Link key={city.name} href={`/${city.code}`} prefetch={false}>
              {city.name}
            </Link>
          ))}
        </VisuallyHidden>
      </nav>
    </header>
  );
};
