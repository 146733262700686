import cls from 'clsx';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { ImageContainer } from '@/components/atoms/ImageContainer/ImageContainer';
import { TextAndImage } from '@/components/atoms/TextAndImage/TextAndImage';
import { H2, P } from '@/components/atoms/Typography/Typography';
import { CloudinaryImage } from '@/components/local/CloudinaryImage/CloudinaryImage';
import { TestRideDialog } from '@/components/local/TestRideDialog/TestRideDialog';
import { textAndImageSize } from '@/containers/HomePage/textAndImageSizes';
import { testRideHomeBanner } from '@/data/testRideConfig';
import { getCountryConfigFromLocale } from '@/lib/i18n/getCountryConfigFromLocale';
import { useData } from '@/lib/pageData/pageDataContext';
import { CityData } from '@/types/CityData';
import { shouldShowTestRideCta } from '@/utils/testRide';
import styles from './TestRideBanner.module.scss';

interface Props {
  className?: string;
  allCities: CityData[];
}

export const TestRideBanner = ({ allCities, className }: Props) => {
  const { locale } = useRouter();
  const { t } = useTranslation();
  const { page } = useData<'home' | 'ebikes'>();
  const country = getCountryConfigFromLocale(locale!).countryCode;
  const bannerUrl = page.images.swapfietsTestRide;
  const bannerConfig = testRideHomeBanner[country];

  if (!bannerConfig || !bannerUrl) return null;

  let headingText = '';
  let paragraphText = '';
  let testRideCode = '';
  if ('cityCode' in bannerConfig) {
    const currentCity = allCities.find(
      city => city.code === bannerConfig.cityCode
    );

    if (
      !currentCity ||
      !shouldShowTestRideCta(country, currentCity, bannerConfig.bike)
    )
      return null;

    const city = t(`common:cities.${currentCity.code}`, currentCity.name);
    testRideCode = currentCity.code;
    headingText = t('testRide.heading_city', {
      city,
    });

    paragraphText = t('testRide.text_long', {
      city,
    });
  } else {
    testRideCode = country;
    const currentCity1 = allCities.find(
      city => city.code === bannerConfig.cityCodes[0]
    );
    const currentCity2 = allCities.find(
      city => city.code === bannerConfig.cityCodes[1]
    );

    if (bannerConfig.cityCodes.length !== 2 || !currentCity1 || !currentCity2)
      return null;

    const city1 = t(`common:cities.${currentCity1.code}`, currentCity1.name);
    const city2 = t(`common:cities.${currentCity2.code}`, currentCity2.name);

    headingText = t('testRide.heading_cities', {
      city1,
      city2,
    });

    paragraphText = t('testRide.text_long_cities', {
      city1,
      city2,
    });
  }

  return (
    <TextAndImage
      className={cls(styles.container, className)}
      classRight={cls('typography', styles.right)}
      left={
        <ImageContainer ratioWidth={588} ratioHeight={394}>
          <CloudinaryImage
            src={bannerUrl}
            alt=""
            layout="fill"
            objectFit="cover"
            className={styles.image}
            sizes={textAndImageSize}
          />
        </ImageContainer>
      }
      right={
        <>
          <H2>{headingText}</H2>
          <P>{paragraphText}</P>
          <TestRideDialog
            bike={bannerConfig.bike}
            country={country}
            testRideCode={testRideCode}
            ctaProps={{ variant: 'contained', color: 'primary' }}
            productAssetCategoryCode="ebike"
          />
        </>
      }
    />
  );
};
