import { FaBicycle } from '@react-icons/all-files/fa/FaBicycle';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import { FaShippingFast } from '@react-icons/all-files/fa/FaShippingFast';
import cls from 'clsx';
import { useTranslation, Trans } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';
import { useLocalStorage, useWindowScroll } from 'react-use';
import {
  AnchorButton,
  Button,
  ButtonProps,
} from '@/components/atoms/Button/Button';
import { Container } from '@/components/atoms/Layout/Layout';
import { TextAndImage } from '@/components/atoms/TextAndImage/TextAndImage';
import { H2, H3, P } from '@/components/atoms/Typography/Typography';
import { BikesOverview } from '@/components/local/BikesOverview/BikesOverview';
import { SAVED_CITY } from '@/components/local/CitySelectorForm/constants';
import { CloudinaryImage } from '@/components/local/CloudinaryImage/CloudinaryImage';
import { IntroWeeksBanner } from '@/components/local/IntroWeeks/IntroWeeksBanner';
import { NavLink } from '@/components/local/NavLink/NavLink';
import { PageFaq } from '@/components/local/PageFaq/PageFaq';
import { SeoNavBar } from '@/components/local/SeoNavBar/SeoNavBar';
import { SwapfietsApp } from '@/components/local/SwapfietsApp/SwapfietsApp';
import { SwapfietsService } from '@/components/local/SwapfietsService/SwapfietsService';
import { TestRideBanner } from '@/components/local/TestRideBanner/TestRideBanner';
import { HowItWorks } from '@/components/molecules/HowItWorks/HowItWorks';
import { CitySelectorCity } from '@/components/organisms/CitySelector/CitySelector';
import nameSpacesConfig from '@/config/config.nameSpaces.json';
import { HeaderSearch } from '@/containers/HomePage/components/HeaderSearch/HeaderSearch';
import { Sustainability } from '@/containers/HomePage/components/Sustainability/Sustainability';
import { Footer } from '@/containers/PageLayout/components/Footer/Footer';
import { Navbar } from '@/containers/PageLayout/components/Navbar/Navbar';
import { PageLayout } from '@/containers/PageLayout/PageLayout';
import { productCategories } from '@/data/productsConfig';
import { getCountryConfigFromLocale } from '@/lib/i18n/getCountryConfigFromLocale';
import { useData } from '@/lib/pageData/pageDataContext';
import { isExperimentActive, useLogEvent } from '@/lib/statsig';
import { CityData } from '@/types/CityData';
import { TransformedAssetCategoryResults } from '@/types/TransformedAssetCategoryResults';
import { getAlternateList } from '@/utils/getAlternateList';
import { getCitiesFilteredByLocale } from '@/utils/getCitiesFilteredByLocale';
import { scrollToSmoothly } from '@/utils/scrollToSmoothly';
import { getThreeTierExperimentProducts } from '@/utils/threeTier';
import { SideNavbar } from '../PageLayout/components/SideNavbar';
import styles from './HomePage.module.scss';
import { textAndImageSize } from './textAndImageSizes';

export interface HomePageProps {
  allCities: CityData[];
  productList: TransformedAssetCategoryResults;
}

const ALTERNATE_LIST = getAlternateList();

const CTAButton = ({ children, ...rest }: ButtonProps) => (
  <Button
    {...rest}
    variant="contained"
    color="secondary"
    data-test-id="bikes-cta"
  >
    {children}
  </Button>
);

export const HomePage = ({ allCities, productList }: HomePageProps) => {
  const router = useRouter();
  const { t } = useTranslation([
    nameSpacesConfig.common,
    nameSpacesConfig.pages,
  ]);
  const { x: windowX } = useWindowScroll();
  const searchRef = useRef<HTMLInputElement>(null);
  const headerRef = useRef<HTMLInputElement>(null);
  const { navLinks, page } = useData<'home'>();
  const [savedCity, setSavedCity] =
    useLocalStorage<CitySelectorCity>(SAVED_CITY);

  useLogEvent('Homepage');

  const isTestVersion = isExperimentActive('homepageStorytelling');
  const i18nKey = isTestVersion ? 'pages:home.test' : 'pages:home';

  const { countryCode } = getCountryConfigFromLocale(router.locale!);
  const headerNotInView =
    headerRef.current &&
    windowX > headerRef.current.getBoundingClientRect().bottom;
  const localCities = getCitiesFilteredByLocale(router.locale!, allCities);

  if (localCities?.length === 1 && savedCity?.code !== localCities[0].code) {
    setSavedCity(localCities[0]);
  }

  const buttonProps = {
    children: t(`${i18nKey}.cityCTA`),
    onClick: () => router.push('/bikes'),
  };
  const howItWorksList = [
    [
      <FaSearch key="icon1" />,
      t(`${i18nKey}.howItWorks.heading1`),
      t(`${i18nKey}.howItWorks.text1`),
    ],
    [
      <FaBicycle key="icon2" />,
      t(`${i18nKey}.howItWorks.heading2`),
      <Trans
        key={
          isTestVersion
            ? 'test.howItWorks.text2WithLink'
            : 'howItWorks.text2WithLink'
        }
        i18nKey={`${i18nKey}.howItWorks.text2WithLink`}
        components={{
          navLink: (
            <NavLink color="primary" link={navLinks.ebikes}>
              {' '}
            </NavLink>
          ),
        }}
      />,
    ],
    [
      <FaShippingFast key="icon3" />,
      t(`${i18nKey}.howItWorks.heading3`),
      t(`${i18nKey}.howItWorks.text3`),
    ],
  ] as [JSX.Element, string, string][];

  const navigationMenu = (
    <>
      <div
        className={cls(styles.scrollWrapper, {
          [styles.scrollWrapperVisible]: headerNotInView,
        })}
      >
        <CTAButton
          className={styles.scrollToSearchBtn}
          slimPadding
          {...buttonProps}
        />
      </div>
      <SideNavbar />
    </>
  );

  const scrollToSearch = () => {
    if (searchRef.current) {
      scrollToSmoothly({
        position: searchRef.current.offsetTop,
      });
      searchRef.current?.focus({ preventScroll: true });
    }
  };

  return (
    <PageLayout
      header={
        <>
          <SeoNavBar
            localCities={localCities}
            navLinks={[navLinks.bikes, navLinks.ebikes]}
          />
        </>
      }
      metaTags={{
        title: t('pages:home.metaTags.title', { defaultValue: null }),
        description: t('pages:home.metaTags.description', {
          country: t(`common:countries.${countryCode}`),
          defaultValue: null,
        }),
        alternateList: ALTERNATE_LIST,
      }}
      navBar={<Navbar rightSlot={navigationMenu} />}
      footer={<Footer />}
    >
      <HeaderSearch
        ref={headerRef}
        inputRef={searchRef}
        currentCountry={countryCode}
        allCities={allCities}
        localCities={localCities}
      />
      <BikesOverview
        productList={
          getThreeTierExperimentProducts(countryCode, productList) ||
          productList
        }
      />
      <HowItWorks
        headingText={t(
          isTestVersion
            ? 'pages:home.test.howItWorks.headingMain'
            : 'common:howItWorks.headingMain'
        )}
        howItWorksList={howItWorksList}
      />
      <Container>
        <TestRideBanner allCities={allCities} />
        <SwapfietsService />
        <section className={styles.section}>
          {!!isTestVersion && (
            <TextAndImage
              as="div"
              className={styles.members}
              classRight={styles.membersRight}
              classLeft={cls('typography', styles.membersLeft)}
              wrapOnTop="right"
              id="swapfiets-members"
              right={
                <CloudinaryImage
                  src={page.images.swapfietsMembers}
                  alt={t('pages:home.test.members.image.alt')}
                  layout="fill"
                  objectFit="cover"
                  objectPosition="60%"
                  sizes={textAndImageSize}
                />
              }
              left={
                <>
                  <H2>{t('pages:home.test.members.heading')}</H2>
                  <P>
                    <Trans
                      i18nKey="pages:home.test.members.text"
                      components={{ bold: <strong /> }}
                    />
                  </P>
                  <Button
                    onClick={scrollToSearch}
                    type="button"
                    variant="contained"
                    color="secondary"
                  >
                    {t('pages:home.test.members.cta')}
                  </Button>
                </>
              }
            />
          )}
        </section>
        <SwapfietsApp />
        <PageFaq />
        {/*
          All the page components should not use hidden margins/paddings.
          Those should be defined outside.
          TODO: create <Spacing {...} /> component that can be rendered
          as a section with the given spacing values.
        */}
        <section className={styles.section} data-test-id="from-swapfiets">
          <H2>{t('pages:home.fromSwapfiets.headingMain')}</H2>
          <TextAndImage
            as="div"
            className={styles.fromSwapfietsTextAndImage}
            classLeft={cls(styles.fromSwapfietsImage)}
            classRight={cls('typography', styles.fromSwapfietsText)}
            wrapOnTop="left"
            left={
              <CloudinaryImage
                src={page.images.powerOnePromo}
                alt=""
                layout="fill"
                objectFit="cover"
                objectPosition="50% 90%"
                sizes={textAndImageSize}
              />
            }
            right={
              <>
                <H3 version="level4">
                  {t('pages:home.fromSwapfiets.powerOnePromo.heading')}
                </H3>
                <P>{t('pages:home.fromSwapfiets.powerOnePromo.text')}</P>
                <Link
                  href={{
                    pathname: '/bikes',
                    query: { type: productCategories.eBike },
                  }}
                  prefetch={false}
                >
                  <AnchorButton>
                    <CTAButton fullWidth>
                      {t('pages:home.fromSwapfiets.powerOnePromo.cta')}
                    </CTAButton>
                  </AnchorButton>
                </Link>
              </>
            }
          />
          <IntroWeeksBanner />
        </section>
        <section className={styles.section}>
          <Sustainability />
        </section>
      </Container>
    </PageLayout>
  );
};
