import cls from 'clsx';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { AnchorButton } from '@/components/atoms/Button/Button';
import { TextAndImage } from '@/components/atoms/TextAndImage/TextAndImage';
import { H2, P } from '@/components/atoms/Typography/Typography';
import { CloudinaryImage } from '@/components/local/CloudinaryImage/CloudinaryImage';
import { textAndImageSize } from '@/containers/HomePage/textAndImageSizes';
import { introWeekCountries } from '@/data/introWeeks';
import { getCountryConfigFromLocale } from '@/lib/i18n/getCountryConfigFromLocale';
import { useData } from '@/lib/pageData/pageDataContext';
import styles from './IntroWeeks.module.scss';

export const IntroWeeksBanner = () => {
  const { t } = useTranslation();
  const { navLinks, page } = useData<'home'>();
  const { locale } = useRouter();
  const country = getCountryConfigFromLocale(locale!).countryCode;
  const isIntroWeekCountry = introWeekCountries.includes(country);

  if (isIntroWeekCountry && navLinks.introWeeks) {
    return (
      <TextAndImage
        as="div"
        className={styles.wrapper}
        classRight={cls(styles.image)}
        classLeft={cls(styles.text)}
        wrapOnTop="left"
        left={
          <>
            <H2 color="white">{t('introWeeks.heading1')}</H2>
            <P className={styles.description} color="white">
              {t('introWeeks.text')}
            </P>
            <AnchorButton
              variant="contained"
              color="secondary"
              href={navLinks.introWeeks.url}
              className={styles.buttonLink}
            >
              {t('introWeeks.link-text')}
            </AnchorButton>
          </>
        }
        right={
          <CloudinaryImage
            src={page.images.introWeeks}
            layout="fill"
            alt="Intro Week"
            objectFit="cover"
            objectPosition="50% 30%"
            className={styles.image}
            sizes={textAndImageSize}
          />
        }
      />
    );
  } else return null;
};
