import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import cls from 'clsx';
import React from 'react';
import { Li, TypographyProps } from '@/components/atoms/Typography/Typography';
import styles from './Usp.module.scss';

interface Props extends TypographyProps {
  className?: string;
  items: string[];
}

export const Usp = ({ className, items, ...rest }: Props) => (
  <ul className={cls(styles.list, className)}>
    {items.map(el => (
      <Li key={el} {...rest}>
        <FaCheck />
        {el}
      </Li>
    ))}
  </ul>
);
