import { TransformerOption } from '@cld-apis/types';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { ForwardedRef, useState } from 'react';
import { useIsomorphicLayoutEffect, useMedia } from 'react-use';
import { H1 } from '@/components/atoms/Typography/Typography';
import { Usp } from '@/components/atoms/Usp/Usp';
import { CitySelectorForm } from '@/components/local/CitySelectorForm';
import { CloudinaryImage } from '@/components/local/CloudinaryImage/CloudinaryImage';
import nameSpacesConfig from '@/config/config.nameSpaces.json';
import { useData } from '@/lib/pageData/pageDataContext';
import { isExperimentActive } from '@/lib/statsig';
import breakpoints from '@/theme/breakpoints.module.scss';
import { CityData } from '@/types/CityData';
import { Countries } from '@/types/Countries';
import { HomePage } from '@/types/PageData';
import { navigateToCityPage } from '@/utils/navigate/navigateToCityPage';
import { TestingPilotWidget } from './abTestComponents/TestingPilotWidget';
import styles from './HeaderSearch.module.scss';

interface Props {
  allCities: CityData[];
  currentCountry: Countries;
  inputRef: ForwardedRef<HTMLInputElement>;
  localCities?: CityData[];
}

export const HeaderSearch = React.forwardRef<HTMLDivElement, Props>(
  function HeaderSearchBase(
    { allCities, inputRef, localCities, currentCountry },
    ref
  ) {
    const isBigScreen = useMedia('(min-width: 768px)', false);
    const isSmallScreen = !useMedia(
      `(min-width: ${breakpoints.mediaSM})`,
      false
    );
    const [showDesktopLayout, setDesktopLayout] = useState(false);
    const router = useRouter();
    const { t } = useTranslation([
      nameSpacesConfig.common,
      nameSpacesConfig.pages,
    ]);
    const { page, trustPilot } = useData<'home'>();

    useIsomorphicLayoutEffect(() => {
      setDesktopLayout(isBigScreen);
    }, [isBigScreen]);

    const textColor =
      showDesktopLayout || page.images?.showOverlay ? 'white' : 'default';
    const trustPilotTheme =
      showDesktopLayout || page.images?.showOverlay ? 'dark' : 'light';

    const isTestVersion = isExperimentActive('homepageStorytelling');
    const i18nKey = isTestVersion ? 'pages:home.test' : 'pages:home';
    const uspItems = !isTestVersion
      ? [
          t('pages:home.usp.text1'),
          t('pages:home.usp.text2'),
          t('pages:home.usp.text3'),
        ]
      : [
          t('pages:home.test.usp.text1'),
          t('pages:home.test.usp.text2'),
          t('pages:home.test.usp.text3'),
          t('pages:home.test.usp.text4'),
        ];

    return (
      <div className={styles.background}>
        <div
          className={styles.container}
          data-test-id="header-search-container"
          ref={ref}
        >
          {page.images?.showOverlay || showDesktopLayout ? (
            <CloudinaryImage
              placeholderWidth={200}
              alt=""
              layout="fill"
              objectFit="cover"
              priority
              transformationOptions={width =>
                getTransformOptions(width, isSmallScreen, false)
              }
              objectPosition={getObjectPosition(
                page.images.objectPosition,
                isSmallScreen,
                false
              )}
              className={styles.imagePosition}
              src={getImageSrc(false, isSmallScreen, page.images)}
            />
          ) : null}
          {!!page.images?.showOverlay && <div className={styles.overlay} />}
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <H1 version="level2" color={textColor} className={styles.heading}>
                {t(`${i18nKey}.header.headingMain`)}
              </H1>
              <Usp color={textColor} items={uspItems} />
              <div className={styles.search}>
                <CitySelectorForm
                  cityList={allCities}
                  inputRef={inputRef}
                  localCityList={localCities}
                  label={t(`${i18nKey}.header.label`)}
                  labelClass={styles.label}
                  labelColor={textColor}
                  onSelect={navigateToCityPage(router)}
                  submitLabel={t(`${i18nKey}.cityCTA`)}
                />
                <TestingPilotWidget
                  locale={router.locale!}
                  trustPilotTheme={trustPilotTheme}
                  trustPilot={trustPilot}
                  currentCountry={currentCountry}
                  isBigScreen={isBigScreen}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export const getTransformOptions = (
  width: number,
  isSmallScreen: boolean,
  isTestVersion: boolean
): TransformerOption => {
  const isMediumScreen = width <= 1200 && isSmallScreen;

  if (isTestVersion) {
    return {
      resize: {
        type: 'scale',
        width: isMediumScreen ? 0.5 : width,
      },
    };
  }

  if (isMediumScreen) {
    return {
      resize: {
        type: 'crop',
        width: 828,
        aspectRatio: '3:4',
      },
      gravity: 'auto:subject',
      quality: 35,
    };
  }

  return {
    resize: {
      type: 'scale',
      width,
    },
  };
};

const getObjectPosition = (
  pageImagesObjectPosition: string,
  isSmallScreen: boolean,
  isTestVersion: boolean
): string => {
  if (isTestVersion) {
    return isSmallScreen ? '100% 100%' : '100% 50%';
  }

  return pageImagesObjectPosition;
};

const getImageSrc = (
  isTestVersion: boolean,
  isSmallScreen: boolean,
  pageImages: HomePage['images']
): string => {
  if (isTestVersion) {
    return isSmallScreen ? pageImages.greyHeaderMobile : pageImages.greyHeader;
  }

  return pageImages.newHeader;
};
