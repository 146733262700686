import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import cls from 'clsx';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import React from 'react';
import { Ul } from '@/components/atoms/List/List';
import { TextAndImage } from '@/components/atoms/TextAndImage/TextAndImage';
import { H2, Li } from '@/components/atoms/Typography/Typography';
import { AppStoreLinks } from '@/components/molecules/AppStoreLinks/AppStoreLinks';
import nameSpacesConfig from '@/config/config.nameSpaces.json';
import { textAndImageSize } from '@/containers/HomePage/textAndImageSizes';
import { loaderCloudinary } from '@/lib/cloudinary/loaderCloudinary';
import { useData } from '@/lib/pageData/pageDataContext';
import styles from './SwapfietsApp.module.scss';

const STEPS_NUMBER = 4;

interface Props {
  className?: string;
}

export const SwapfietsApp = ({ className }: Props) => {
  const { t } = useTranslation([
    nameSpacesConfig.common,
    nameSpacesConfig.pages,
  ]);
  const { page, navLinks } = useData<'home'>();
  return (
    <TextAndImage
      className={cls(styles.container, className)}
      classLeft={cls('typography', styles.left)}
      classRight={styles.right}
      wrapOnTop="right"
      id="swapfiets-app"
      left={
        <>
          <H2>{t('pages:home.app.heading')}</H2>
          <Ul className={styles.list}>
            {Array.from({ length: STEPS_NUMBER }).map((_, i) => {
              const stepLabel = t(`pages:home.app.step${i + 1}`);
              return (
                stepLabel && (
                  <Li version="level5" key={stepLabel}>
                    <FaCheck />
                    <span>{stepLabel}</span>
                  </Li>
                )
              );
            })}
          </Ul>
          <AppStoreLinks
            className={styles.apps}
            googlePlayUrl={navLinks.playStore.url}
            appStoreUrl={navLinks.appStore.url}
            googlePlayTitle={t('common:navLinks.playStore')}
            appStoreTitle={t('common:navLinks.appStore')}
          />
        </>
      }
      right={
        <div className={styles.imageContainer}>
          <Image
            src={page.images.swapfietsApp}
            alt={t('pages:home.app.image.alt')}
            objectFit="contain"
            layout="fill"
            loader={loaderCloudinary}
            sizes={textAndImageSize}
          />
        </div>
      }
    />
  );
};
