import { GetStaticProps, NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React from 'react';
import nameSpacesConfig from '@/config/config.nameSpaces.json';
import { HomePage } from '@/containers/HomePage';
import type { HomePageProps } from '@/containers/HomePage/HomePage';
import { PageHead } from '@/containers/PageLayout/components/PageHead/PageHead';
import { checkForDefaultLocale } from '@/lib/i18n/checkForDefaultLocale';
import { getCountryConfigFromLocale } from '@/lib/i18n/getCountryConfigFromLocale';
import { getServerSideTranslations } from '@/lib/i18n/getServerSideTranslations';
import { PageDataContextProvider } from '@/lib/pageData/pageDataContext';
import { CMSData } from '@/types/CMSData';
import { TransformedAssetCategoryResults } from '@/types/TransformedAssetCategoryResults';
import { fetchCities } from '@/utils/fetch/fetchCities';
import { fetchProductListByCountry } from '@/utils/fetch/fetchProductListByCountry';
import { getAlternateList } from '@/utils/getAlternateList';
import { getProductConfig } from '@/utils/getProductConfig';
import { importCmsJson } from '@/utils/importCmsJson';
import { sortProductList } from '@/utils/sortProductList';

const LocaleSelectorPage = dynamic<Record<string, unknown>>(() =>
  import('@/containers/LocaleSelectorPage/LocaleSelectorPage').then(
    mod => mod.LocaleSelectorPage
  )
);

interface PropsForHomePage {
  allCities: HomePageProps['allCities'];
  data: CMSData<'home'>;
  defaultLocale: false;
  productList: TransformedAssetCategoryResults;
}

interface PropsForLocalePage {
  allCities?: undefined;
  data?: undefined;
  defaultLocale: true;
}

function propsWithDefaultLocale(props: unknown): props is PropsForLocalePage {
  return (props as PropsForLocalePage).defaultLocale;
}

type Props = PropsForHomePage | PropsForLocalePage;

const Home: NextPage<Props> = props => {
  const router = useRouter();
  const { t } = useTranslation([
    nameSpacesConfig.common,
    nameSpacesConfig.pages,
  ]);

  if (propsWithDefaultLocale(props)) {
    return <LocaleSelectorPage />;
  }

  const { countryCode } = getCountryConfigFromLocale(router.locale!);

  return (
    <PageDataContextProvider data={props.data}>
      {/*
        TODO: remove after the next A/B test is finished
        https://dev.azure.com/swapfiets/Digital/_workitems/edit/24610
      */}
      <PageHead
        title={t('pages:home.metaTags.title', { defaultValue: null })}
        description={t('pages:home.metaTags.description', {
          country: t(`common:countries.${countryCode}`),
          defaultValue: null,
        })}
        alternateList={getAlternateList()}
      />
      <HomePage allCities={props.allCities} productList={props.productList} />
    </PageDataContextProvider>
  );
};
export default Home;

export const getStaticProps: GetStaticProps<Props> = async ({ locale }) => {
  if (checkForDefaultLocale(locale!)) {
    return {
      props: {
        defaultLocale: true,
      },
    };
  }

  const data = await importCmsJson(locale!, 'home');
  const allCities = await fetchCities();

  const { countryCode } = getCountryConfigFromLocale(locale!);
  const productList = await fetchProductListByCountry(countryCode);

  if (!productList?.length) {
    return {
      notFound: true,
    };
  }

  const transformedProductsList = productList
    .filter(p => p.assetCategoryCode !== 'pwr-plus')
    .map(el => ({
      ...el,
      productConfig: getProductConfig({
        assetCategoryCode: el.assetCategoryCode,
      }),
    }));

  return {
    props: {
      defaultLocale: false,
      data,
      allCities,
      productList: sortProductList(transformedProductsList),
      ...(await getServerSideTranslations(locale!, [
        nameSpacesConfig.common,
        nameSpacesConfig.faq,
        nameSpacesConfig.pages,
        nameSpacesConfig.product,
      ])),
    },
    revalidate: 30 * 60,
  };
};
