interface Fetch {
  url: RequestInfo;
  config?: RequestInit;
  name: string;
}

export class FetchError extends Error {
  status: number;
  url: RequestInfo;

  constructor(message: string, status: number, url: RequestInfo) {
    super(message);
    this.name = this.constructor.name;
    this.status = status;
    this.url = url;
  }
}

export async function processFetchRequest<R>({
  url,
  config,
  name,
}: Fetch): Promise<R> {
  const results = await fetch(url, config);

  if (!results.ok) {
    throw new FetchError(
      `${name} method failed, ${results.statusText}`,
      results.status,
      url
    );
  }
  return await results.json();
}
