import dynamic from 'next/dynamic';
import type { CitySelectorFormProps } from '@/components/local/CitySelectorForm/CitySelectorForm';

export const CitySelectorForm = dynamic<CitySelectorFormProps>(
  () => import('./CitySelectorForm').then(mod => mod.CitySelectorFormComponent),
  // We initial load a div to avoid a layout shift on the image banner
  {
    ssr: false,
    loading: () => <div style={{ height: '194px', width: '100%' }} />,
  }
);
