import type {
  BestLocaleCityBodyReq,
  BestLocaleCityResponse,
} from '@/pages/api/best_locale_city';
import { CityData } from '@/types/CityData';
import { processFetchRequest } from '@/utils/fetch/processFetchRequest';

export const fetchLocalisedPathForCity = async (
  city: CityData,
  currentLocale: string
) => {
  const body: BestLocaleCityBodyReq = {
    city,
    currentLocale,
  };

  const data = await processFetchRequest<BestLocaleCityResponse>({
    url: '/api/best_locale_city',
    config: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
    name: fetchLocalisedPathForCity.name,
  });

  return { bestFitLocale: data.bestFitLocale };
};
