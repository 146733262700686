interface LocaleByLanguage {
  countryLocales: string[];
  langCode: string;
}

export function getLocaleByLanguage({
  countryLocales,
  langCode,
}: LocaleByLanguage) {
  const selectedLocale = countryLocales.find(locale => {
    const languageCode = locale.split(/[-_]/)[0];
    return languageCode === langCode;
  });

  return selectedLocale ?? countryLocales[0];
}
