import cls from 'clsx';
import React, { useEffect, useRef } from 'react';
import { A } from '@/components/atoms/A/A';
import { Countries, countries } from '@/types/Countries';
import styles from './trustPilotWidget.module.scss';

interface Props {
  className?: string;
  locale: string;
  dataTemplateId: string;
  dataBusinessUnitId: string;
  url: string;
  theme?: 'dark' | 'light';
  currentCountry: Countries;
}

const mapLightColorToCountries: Countries[] = [countries.FR, countries.ES];

const MockStars = ({ currentCountry }: Pick<Props, 'currentCountry'>) => (
  <div className={styles.container}>
    {[1, 2, 3, 4, 5].map(item => (
      <div key={item} className={cls(styles.inner, styles[currentCountry])}>
        <svg
          className={styles.svg}
          aria-labelledby="trustpilotLogo"
          width="18"
          height="18"
          viewBox="0 0 41 41"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M30.141707 11.07005H18.63164L15.076408.177071l-3.566342 10.892977L0 11.059002l9.321376 6.739063-3.566343 10.88193 9.321375-6.728016 9.310266 6.728016-3.555233-10.88193 9.310266-6.728016z" />
          <path
            className="tp-logo__star-notch"
            fill={
              mapLightColorToCountries.includes(currentCountry)
                ? styles['greenLight']
                : styles['greenDark']
            }
            d="M21.631369 20.26169l-.799928-2.463625-5.755033 4.153914z"
          />
        </svg>
      </div>
    ))}
  </div>
);

export const TrustPilotWidget = ({
  className,
  locale,
  dataBusinessUnitId,
  dataTemplateId,
  url,
  theme = 'light',
  currentCountry,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [theme]);

  return (
    <>
      <MockStars currentCountry={currentCountry} />
      <div
        className={cls('trustpilot-widget', className)}
        data-locale={locale}
        data-template-id={dataTemplateId}
        data-businessunit-id={dataBusinessUnitId}
        data-style-height="40px"
        data-style-width="100%"
        data-theme={theme}
        ref={ref}
        data-min-review-count="4"
        data-no-reviews="hide"
      >
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <A
          color="primary"
          href={url}
          target="_blank"
          rel="noopener"
          aria-label="trustpilot"
        />
      </div>
    </>
  );
};
